import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../redux/slices/ttdatahub";
import { useEffect } from "react";
import { User } from "react-feather";
// import ResponsiveGrid from "../../pages/ResponsiveGrid";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";

// the following is to display name and orga name in bold

// function DisplayUser() {
//   const ttdatahub = useSelector((state) => state.ttdatahub);
//   if (ttdatahub.user) {
//     return (
//       <span style={{ fontWeight: "bold", color: "black" }}>
//         {ttdatahub.user.mail}
//       </span>
//     );
//   } else {
//     return (
//       <span style={{ fontWeight: "bold", color: "black" }}>
//         Loading Username...
//       </span>
//     );
//   }
// }

// function DisplayOrg() {
//   const ttdatahub = useSelector((state) => state.ttdatahub);
//   if (ttdatahub.orga) {
//     if (IsAdmin(ttdatahub)) {
//       return (
//         <span style={{ fontWeight: "bold", color: "black" }}>
//           Administrator
//         </span>
//       );
//     } else {
//       return (
//         <span style={{ fontWeight: "bold", color: "black" }}>
//           {ttdatahub.orga.orga_name}
//         </span>
//       );
//     }
//   } else {
//     return (
//       <span style={{ fontWeight: "bold", color: "black" }}>
//         Loading Organisation...
//       </span>
//     );
//   }
// }

function DisplayUser() {
  const ttdatahub = useSelector((state) => state.ttdatahub);
  if (ttdatahub.user) {
    return ttdatahub.user.mail;
  } else {
    return "Loading Username...";
  }
}

function DisplayOrg() {
  const ttdatahub = useSelector((state) => state.ttdatahub);
  if (ttdatahub.orga) {
    if (IsAdmin(ttdatahub)) {
      return "Administrator";
    } else {
      return ttdatahub.orga.orga_name;
    }
  } else {
    return "Loading Organisation...";
  }
}

function IsAdmin(ttdatahub) {
  if (ttdatahub.user) {
    if (ttdatahub.user.id_orga === "*") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  // background: ${(props) => props.theme.sidebar.header.background};

  // background: #c3fcfa;
  // background: linear-gradient(
  //   to bottom,
  //   #79fce4,
  //   #f7f9fa
  // ); /* Fading shade of turquoise */

  color: ${(props) => props.theme.header.color};
  max-width: 100%;
  // max-width: 86vw;
  overflow-x: hidden;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const ttdatahub = useSelector((state) => state.ttdatahub);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <NavbarUserDropdown />
            </Grid>
            <Grid item>
              <div style={{ color: "black", fontSize: "16px", margin: 2 }}>
                {DisplayUser(ttdatahub)}
              </div>
              <div style={{ color: "black", fontSize: "14px", margin: 2 }}>
                {DisplayOrg()}
              </div>
            </Grid>

            {/* 
            <Grid item>
              <User size={30} style={{ margin: 2 }} />
            </Grid>
            <Grid item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <User
                  size={33}
                  style={{ marginRight: "10px", marginBottom: "-18px" }}
                />
                <div style={{ color: "black", fontSize: "15px" }}>
                  {DisplayUser(ttdatahub)}
                </div>
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: "13px",
                  display: "block",
                  marginLeft: "44px",
                }}
              >
                {DisplayOrg()}
              </div>
              </Grid>*/}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);

// import React from "react";
// import styled from "@emotion/styled";
// import { withTheme } from "@emotion/react";
// //import { darken } from "polished";
// //import { Search as SearchIcon } from "react-feather";
// //import { useTranslation } from "react-i18next";

// import {
//   Grid,
//   AppBar as MuiAppBar,
//   IconButton as MuiIconButton,
//   Toolbar,
// } from "@mui/material";

// import { Menu as MenuIcon } from "@mui/icons-material";

// import NavbarUserDropdown from "./NavbarUserDropdown";

// const AppBar = styled(MuiAppBar)`
//   background: ${(props) => props.theme.header.background};
//   color: ${(props) => props.theme.header.color};
//   max-width: 100%;
// `;

// const IconButton = styled(MuiIconButton)`
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// /*
// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }

//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)};
//     padding-right: ${(props) => props.theme.spacing(2.5)};
//     padding-bottom: ${(props) => props.theme.spacing(2.5)};
//     padding-left: ${(props) => props.theme.spacing(12)};
//     width: 160px;
//   }
// `;
// */

// const Navbar = ({ onDrawerToggle }) => {
//   //const { t } = useTranslation();

//   return (
//     <React.Fragment>
//       <AppBar position="sticky" elevation={0}>
//         <Toolbar>
//           <Grid container alignItems="center">
//             <Grid item sx={{ display: { xs: "block", md: "none" } }}>
//               <IconButton
//                 color="inherit"
//                 aria-label="Open drawer"
//                 onClick={onDrawerToggle}
//                 size="large"
//               >
//                 <MenuIcon />
//               </IconButton>
//             </Grid>
//             {/*
//             <Grid item>
//               <Search>
//                 <SearchIconWrapper>
//                   <SearchIcon />
//                 </SearchIconWrapper>
//                 <Input placeholder={t("Search")} />
//               </Search>
//             </Grid>
//             */}
//             <Grid item xs />
//             <Grid item>
//               <NavbarUserDropdown />
//             </Grid>
//           </Grid>
//         </Toolbar>
//       </AppBar>
//     </React.Fragment>
//   );
// };

// export default withTheme(Navbar);
